import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/modules/article/components/article-list-paginated/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Form"] */ "/vercel/path0/modules/contact-form/components/form/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/modules/foundation/components/image/ImageBlock.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/modules/foundation/components/image/ImageFill.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoPlayer"] */ "/vercel/path0/modules/foundation/components/video/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GalleryContainer"] */ "/vercel/path0/modules/page/sanity/content-blocks/gallery-container/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/modules/page/sanity/frontpage/FrontPageDraft.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
